import {customTaps} from '../constants/constants';

export const getRedirectlink = (link) => {

    const productPagePrefix = "/product"
    const redirectFromLinks = productPagePrefix + "/incense-list";
    const redirectFromSachetLinks = productPagePrefix + "/sachet-list";
    const redirectToLinks = productPagePrefix + "/collins-incense";
    const redirectFromGoodTimeLinks = productPagePrefix + "/incense-list-goodtimes"
    const redirectToGoodTimeLinks = productPagePrefix + "/collins-incense-edition-goodtimes"
    const redirectToSachetLinks = productPagePrefix + "/collins-sachets";

    if (link.includes(redirectFromGoodTimeLinks)) {
        link = redirectToGoodTimeLinks;
    } else if (link.includes(redirectFromLinks)) {
        link = redirectToLinks;
    } else if (link.includes(redirectFromSachetLinks)) {
        link = redirectToSachetLinks;
    }

    return link;

};

export const isCustomTapProduct = (slug) => {

    // console.log("isCustomTapProduct" , slug);

    if (!slug) {
        return false;
    }

    let isCustomTaps = false;

    for (let i in customTaps) {

        // console.log(slug , customTaps[i].slug);
        if (customTaps[i].slug === slug) {

            // console.log('true!!!');
            isCustomTaps = true;
            break;
        }
    }

    return isCustomTaps;
}
