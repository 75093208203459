import {apiUrl} from "../constants/constants";

let C_IAMPORT_CODE = process.env.REACT_APP_IAMPORT_CODE;
let C_NPAY = '1';

export const initCheckout = (
    pg, //결제 pg사
    method, // 결제 방식
    wcMethod, //wc에서 정의하는 결제방식 명
    wcMethodTitle, //wc에 넘길 결제 방식의 이름
    cartData, //cart/item API에서 넘겨 받는 데이터 를 넣는다. 양식은 아래와 같다.
    // {cartItems: [{…}]
    // cart_hash: "a05fec0af6c3fb2e1bbc0742429a7667"
    // coupon_discount_totals: []
    // fees: []
    // items_counted: 1
    // needs_payment: true
    // needs_shipping: true
    // totals: {subtotal: "29,900원", subtotal_tax: "0", shipping_total: "2,500원", shipping_tax: "0", shipping_taxes: Array(0), …}}
    // setIsSpinnerShow, //결제 동작에 따른 스피너 액션 메서드
    recipientFirstName,
    recipientLastName,
    adress1,
    adress2,
    postCode,
    recipientPhone,
    message
) => {

    console.log('init IMP code inside init checkout');
    // 결제 전처리
    window.IMP.init(C_IAMPORT_CODE);
    makeGlobalSpinner();


    // 스코프 안에서 클로져로 활용될 변수들
    // setIsSpinnerShow(true);

    showGlobalSpinner();

    var myOrder = null;
    // var isProcessing = false;
    var inputPG;
    var inputMethod;
    var inputWcMethod;
    var inputWcMethodTitle;

    inputPG = pg;
    inputMethod = method;
    inputWcMethod = wcMethod;
    inputWcMethodTitle = wcMethodTitle;


    // if (isProcessing) return;

    let orderData = {
        payment_method: inputWcMethod,
        payment_method_title: inputWcMethodTitle,
        shipping: {
            first_name: recipientFirstName,
            last_name: recipientLastName,
            address_1: adress1,
            address_2: adress2,
            postcode: postCode,
            phone: recipientPhone

        },
        customer_note: message
    };


    // basicPayment(state )
    fetch(apiUrl + '/api/initCheckout', {
        method: "POST", headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(orderData)
    }).then(data => {
        // setIsSpinnerShow && setIsSpinnerShow(false);
        // hideGlobalSpinner()
        data.json().then(myOrder => {
            console.log(myOrder)
            if (myOrder != null) {
                // console.log(data);

                reqPay(myOrder);
            }
        })
    }).catch(err => {
        // setIsSpinnerShow && setIsSpinnerShow(false);
        hideGlobalSpinner();
        // isProcessing = false;

        if (err.responseJSON) {
            alert(err.responseJSON.message);
        } else {
            alert('error');
        }
    })
    // $.ajax({
    //     type: 'POST',
    //     dataType: 'json',
    //     xhrFields: {
    //         withCredentials: true
    //     },
    //     contentType: 'application/json',
    //     url: apiUrl + '/api/initCheckout',
    //     data: JSON.stringify(orderData),
    //     success: function (data) {
    //         // setIsSpinnerShow && setIsSpinnerShow(false);
    //         // hideGlobalSpinner()
    //         if (data != null) {
    //             // console.log(data);
    //
    //             myOrder = data;
    //
    //             reqPay(myOrder.order_key);
    //         }
    //     },
    //     error: function (err) {
    //         console.log(err);
    //         // setIsSpinnerShow && setIsSpinnerShow(false);
    //         hideGlobalSpinner();
    //         // isProcessing = false;
    //
    //         if (err.responseJSON) {
    //             alert(err.responseJSON.message);
    //         } else {
    //             alert('error');
    //         }
    //     }
    //
    // });


    function reqPay(myOrder) {
        let param;
        if (inputPG === 'naverco') {
            kakaoPixelForNpay();
            // setIsSpinnerShow && setIsSpinnerShow(false);
            // deleteGlobalSpinner();
            // isProcessing = false;
            param = {
                pg: inputPG,
                pay_method: inputMethod,
                merchant_uid: myOrder.order_key,
                name: getPayTitleFromCartData(),
                amount: myOrder.total,
                buyer_email: myOrder.billing.email,
                buyer_tel: myOrder.billing.phone,
                m_redirect_url: apiUrl + '/api/mobileCheckout/' + myOrder.id,
                naverProducts: getProductsForNpay(),
                notice_url: apiUrl + '/api/npay/notice/' + myOrder.id,
                //notice_url : "https://wp-collins.gitt.co/order-pay/" + myOrder.id + "/?pay_for_order=true&key=" + myOrder.order_key + "&wc-api=WC_Gateway_Iamport_NaverPay",
                naverInterface: {
                    cpaInflowCode: null,
                    naverInflowCode: null,
                    saClickId: null,
                    merchantCustomCode2: myOrder.order_key
                },
                naverCultureBenefit: false,
            };
            setTimeout(() => hideGlobalSpinner(), 3000);

        } else {
            param = {
                pg: inputPG,
                pay_method: inputMethod,
                merchant_uid: myOrder.order_key,
                name: getPayTitleFromCartData(),
                amount: myOrder.total,
                buyer_email: myOrder.billing.email,
                buyer_tel: myOrder.billing.phone,
                m_redirect_url: apiUrl + '/api/mobileCheckout/' + myOrder.id,

            };
            hideGlobalSpinner();

        }
        window.IMP.request_pay(param,
            function (rsp) {

                // setIsSpinnerShow && setIsSpinnerShow(true);
                // makeGlobalSpinner();

                console.log({rsp});
                showGlobalSpinner();
                if (rsp.success) {

                    console.log(rsp);
                    var msg = '결제가 완료되었습니다.';
                    msg += '고유ID : ' + rsp.imp_uid;
                    msg += '상점 거래ID : ' + rsp.merchant_uid;
                    msg += '결제 금액 : ' + rsp.paid_amount;
                    msg += '카드 승인번호 : ' + rsp.apply_num;

                    checkout(rsp.imp_uid, myOrder);
                    // setIsSpinnerShow && setIsSpinnerShow(false);
                    // hideGlobalSpinner();
                } else {
                    let msg = '결제에 실패하였습니다.';
                    msg += '\n' + rsp.error_msg;
                    // setIsSpinnerShow && setIsSpinnerShow(false);
                    hideGlobalSpinner();
                    alert(msg);
                }

            });

    }

    // function removeCouponFromOrder() {
    //     const orderData = {
    //         id: myOrder.id,
    //     };
    //
    //     $.ajax({
    //         type: 'POST',
    //         dataType: 'json',
    //         xhrFields: {
    //             withCredentials: true,
    //         },
    //         contentType: 'application/json',
    //         url: apiUrl + '/api/v2/coupon/remove-from-order',
    //         data: JSON.stringify(orderData),
    //         success: function (data) {
    //             console.log('coupon removed order : ', data);
    //
    //             if (data.success) {
    //                 window.location.href = `/cart`;
    //             }
    //         },
    //         error: function (err) {
    //             alert(err.responseJSON.message);
    //
    //             window.location.reload();
    //         },
    //     });
    // }

    function checkout(uid, myOrder) {

        // setIsSpinnerShow && setIsSpinnerShow(true);
        // makeGlobalSpinner();
        // setOrderNumber(myOrder.id)
        fetch(apiUrl + '/api/processCheckout', {
            method: "POST", headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                id: myOrder.id,
                transaction_id: uid
            })
        }).then(() => {
            hideGlobalSpinner();
            window.location.href = `/order/complete?orderId=${myOrder.id}`;
        }).catch(err => {
            hideGlobalSpinner();
            alert(err.responseJSON.message);
            // setIsSpinnerShow && setIsSpinnerShow(false);

            // isProcessing = false;

        })
        // $.ajax({
        //     type: 'POST',
        //     dataType: 'json',
        //     xhrFields: {
        //         withCredentials: true
        //     },
        //     url: apiUrl + '/api/processCheckout',
        //     data: {
        //         id: myOrder.id,
        //         transaction_id: uid
        //     },
        //     success: function () {
        //
        //         // if (data != null) {
        //         //     console.log(data);
        //         //     // 여기에 thankyou page 연결부를 만들자
        //         //     // setVBankName(data.vbankName);
        //         //     // setVBankNumber(data.vbankNum);
        //         //     // setPayDue(data.vbankDate);
        //
        //         //     // 여기에 포스트 요청을 넣도록 코드를 추가해야 함
        //         //     // setThankYouVisible(true);
        //         //     // setIsAdressFormOpen(false);
        //         //     // setCartButtonVisible(false);
        //
        //         // }
        //         hideGlobalSpinner();
        //         window.location.href = `/order/complete?orderId=${myOrder.id}`;
        //         // setIsSpinnerShow && setIsSpinnerShow(false);
        //
        //
        //         // isProcessing = false;
        //
        //     },
        //     error: function (err) {
        //         hideGlobalSpinner();
        //         alert(err.responseJSON.message);
        //         // setIsSpinnerShow && setIsSpinnerShow(false);
        //
        //         // isProcessing = false;
        //
        //     }
        //
        // });
    }

    function getPayTitleFromCartData() {
        let title = '';
        if (cartData.cartItems) {

            let cnt = cartData.cartItems.length;

            if (cnt > 1) {
                title = cartData.cartItems[0].product_name + ' 외 ' + (cnt - 1) + '건';
            } else {
                title = cartData.cartItems[0].product_name;
            }

        }

        return title;
    }

    function kakaoPixelForNpay() {

        try {

            let itemCnt = 0;

            if (cartData.cartItems) {
                for (let item of cartData.cartItems) {
                    itemCnt = itemCnt + item.quantity;
                }
            }

            let pixelData = {
                total_quantity: itemCnt, // 주문 내 상품 개수(optional)
                total_price: cartData.totals.total_integer, // 주문 총 가격(optional)
                currency: 'KRW', // 주문 가격의 화폐 단위(optional, 기본 값은 KRW)
                products: []
            };


            window.kakaoPixel('1310813083844559940').purchaseNaverCheckout(pixelData);

        } catch (e) {
            console.log('kakaoPixel error :: ', e);
            /* ignore errors from kakaoPixel */
        }

    }

    function getProductsForNpay() {
        if (cartData.cartItems) {
            const products = [];
            for (let item of cartData.cartItems) {
                let targetPrice = item.fee_applied_line_total / item.quantity;
                const productData = {
                    id: item.product_id,
                    name: item.product_name,
                    basePrice: Math.floor(targetPrice),
                    taxType: 'TAX',
                    infoUrl: window.location.origin + '/product/' + item.product.slug,
                    imageUrl: item.product.thumbnail,
                    // options : [],
                    shipping: {
                        groupId: 'shipping-a',
                        method: 'DELIVERY',
                        baseFee: cartData.totals.shipping_origin_total,
                        feePayType: cartData.totals.shipping_origin_total > 0 ? 'PREPAYED' : 'FREE'
                    }
                };

                if (item.variation && item.variation.length > 0) {
                    productData.options = [];
                    for (let variation of item.variation) {

                        let options = [];

                        for (let attr of variation.attributes) {

                            options.push({
                                code: attr.slug,
                                label: attr.name,
                                value: attr.option
                            })

                        }


                        const productOptionData = {
                            optionQuantity: item.quantity,
                            optionPrice: 0,
                            selectionCode: variation.id + '|' + variation.attributes[0].slug,
                            selections: options
                            // selections : [
                            //   {
                            //     code : variation.attributes[0].slug,
                            //     label : variation.attributes[0].name,
                            //     value : variation.attributes[0].option
                            //   }
                            // ]
                        };

                        productData.options.push(productOptionData);
                    }

                } else {
                    //  단순일 경우 개수 조정을 위한 장치
                    productData.quantity = item.quantity;
                }
                products.push(productData);
            }


            console.log('npayProducts::', products);


            return products;

        }


        return [];


    }

    // var postRedirect = function (redirectUrl, arg, value) {
    //     var form = $('<form action="' + redirectUrl + '" method="post">' +
    //         '<input type="hidden" name="' + arg + '" value="' + value + '"></input>' + '</form>');
    //     $('body').append(form);
    //     $(form).submit();
    // };


};


export function makeNpayButton({
                                   elemIds, // Napy 버튼을 부착할 엘리먼트 ID Arr
                                   buttonKey, // 네이버에게 따로 받은 버튼 key
                                   buyAction, //npay 구매 버튼을 누르면 동작할 함수
                                   getProductsForNpayZzim, // 찜을 누르면 동작할 함수,
                                   withZzim //찜 활겅화 여부
                               }) {
    if (C_NPAY != '1') {
        return;
    }
    const type = "A";
    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     // 모바일인 경우
    //     type = "MA"
    // } else {
    //     type = "A"
    // }

    for (let elemId of elemIds) {
        if (typeof naver === 'undefined') {
            return alert("naver pay 이슈")
        }
        window.naver.NaverPayButton.apply({
            BUTTON_KEY: buttonKey,
            TYPE: type, //버튼 스타일
            COLOR: 1, //버튼 색상타입
            COUNT: withZzim ? 2 : 1, // 네이버페이버튼 + 찜하기버튼 모두 출력 여부
            ENABLE: 'Y', //네이버페이 활성여부(재고부족 등에는 N으로 비활성처리)
            EMBED_ID: elemId, //네이버페이 버튼 UI가 부착될 HTML element의 ID
            BUY_BUTTON_HANDLER: function () {
                buyAction();
            },
            WISHLIST_BUTTON_HANDLER: function () {
                // 찜액션 호출은 IMP를 통해서 한다.

                if (withZzim) {
                    window.IMP.naver_zzim({
                        naverProducts: getProductsForNpayZzim()
                    });

                } else {
                    return;
                }
            },
            // WISHLIST_BUTTON_LINK_URL: getProductsForNpayZzim().url
        });
    }
}


export async function buyNowNpayAction({
                                           dataToSend, //실제 옵션 state를 서버에서 받을 수 있는 형식으로 변환한 값
                                           // setIsSpinnerShow,
                                       }) {
    if (dataToSend.length === 0) {
        alert('옵션을 선택해야 합니다');
    } else {

        // setIsSpinnerShow(true);
        makeGlobalSpinner();


        showGlobalSpinner();
        const res = await fetch(apiUrl + '/api/cart/buynow', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend)
        });
        console.log(dataToSend, 'data before server');
        if (res.status === 200) {
            const resCart = await fetch(apiUrl + '/api/cart/item', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const dataCart = await resCart.json();
            console.log(dataCart, 'data after server');
            if (resCart.status === 200) {
                initCheckout('naverco', 'card', 'iamport_naverpay', '네이버페이', dataCart);
            }
        } else {
            // setIsSpinnerShow(false);
            // alert(dataCart.message);
            hideGlobalSpinner();
        }

    }
}


function makeGlobalSpinner() {
    // 중복 로딩되는 것 방지, Npay로 들어온 경우와 바로 initCheckOut하는 경우 두 경우 모두 스피너가 필요하기 때문
    if (document.getElementById('injectedSpinner')) {
        return;
    }

    const spinnerEl = document.createElement('div');

    spinnerEl.id = 'injectedSpinner';
    document.body.appendChild(spinnerEl);
    spinnerEl.innerHTML = `<div class="loadingio-spinner" >
        <div class="loadingio-spinner-dual-ball-hqgr8n5kmog"><div class="ldio-gv9lev5i3zo">
            <div></div><div></div><div></div>
        </div></div>
    </div>`;
}

function showGlobalSpinner() {
    const spinnerEl = document.getElementById('injectedSpinner');
    if (spinnerEl) {
        spinnerEl.style.display = 'block';
    }

}

function hideGlobalSpinner() {
    const spinnerEl = document.getElementById('injectedSpinner');
    if (spinnerEl) {
        spinnerEl.style.display = 'none';
    }


}


export function getProductsForNpayZzim(
    {
        id,
        name,
        slug,
        uprice,
        image
    }) {

    const products = [];
    const productZzimData = {
        id: id,
        name: name,
        desc: 'Collins',
        uprice: uprice,
        url: window.location.origin + '/product/' + slug,
        image: image,
    };
    products.push(productZzimData);
    return products;


}
