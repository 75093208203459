import React, {useEffect, useState} from "react"
import "./IsModal.scss"
import {callChatPlugIn, fetchAndReceiveJSON, getSessionStorage, setSessionStorage} from "../../utils/utils";
import {apiUrl} from "../../constants/constants";
import {useLocation} from "react-router-dom";

interface IModalState {
    modal_desktop_image_url: string;
    modal_mobile_image_url: string;
    modal_date_range: {
        is_set_period: boolean;
        is_visible_modal_end_date?: string;
        is_visible_modal_start_date?: string;
    }
    modal_visible_priority: string;
    modal_visible_page: any
    modal_button_setting: {
        button_available: boolean, button_background_color: string, button_font_color: string,
        button_font_text: string, action_on_click: string, link_url?: string
    }[]
}

let timeoutId = undefined
const IsModal = () => {
    const [mobileImg, setMobileImg] = useState<string | null>()
    const [pcImg, setPcImg] = useState<string | null>()
    const [isVisible, setIsVisible] = useState(false)
    const [modalData, setModalData] = useState([])
    const [imgLoaded, setImgLoaded] = useState(false)
    const [buttonData, setButtonData] = useState<{
        button_available: boolean, button_background_color: string, button_font_color: string,
        button_font_text: string, action_on_click: string, link_url?: string
    }[]>()
    const location = useLocation()
    const [scrollPosition, setScrollPosition] = useState(0)
    const [slugs, setSlugs] = useState<any>(null)

    const checkModal = (data) => {
        const currentSlug = window.location.href;
        let targetSessionId = ''
        let filterSlug = [];
        if (data.modal_visible_page.exposure_target_range?.modal_slug === "pages") {
            filterSlug = data.modal_visible_page.exposure_target_range.per_page_type?.filter(item => {
                if (item === "/") {
                    targetSessionId = item
                    return item === currentSlug
                } else {
                    if (currentSlug.match(`^${item}`)) {
                        targetSessionId = item
                        return true
                    }
                }
            })
        } else if (data.modal_visible_page.exposure_target_range?.modal_slug === "url_unit") {
            filterSlug = data.modal_visible_page.exposure_target_range.url_unit_detailed_settings?.filter(item => item.slug_url !== "")
            filterSlug = filterSlug.filter(item => {
                if (item.url_text_condition === "Included") {
                    const reg = new RegExp(`${item.slug_url}`, 'gi')
                    if (currentSlug.match(reg)) {
                        targetSessionId = item.slug_url
                        return true
                    }
                } else if (item.url_text_condition === "starts_with") {
                    const reg = new RegExp(`^${item.slug_url}`, 'gi')
                    if (currentSlug.match(reg)) {
                        targetSessionId = item.slug_url
                        return true
                    }
                } else if (item.url_text_condition === "ends_with") {
                    const reg = new RegExp(`${item.slug_url}$`, 'gi')
                    targetSessionId = item.slug_url
                    if (currentSlug.match(reg)) {
                        targetSessionId = item.slug_url
                        return true
                    }
                }
            })
            if (filterSlug.some(item => item.url_exposure_conditions === "except")) {
                filterSlug = []
            }
        } else if (data.modal_visible_page.exposure_target_range?.modal_slug === "all") {
            targetSessionId = 'all'
            filterSlug.push({slug: 'all'})
        }

        if (filterSlug.length > 0) {
            if (data.modal_visible_page.modal_exposure_method === "session") {
                const sessionId = data.modal_mobile_image_url + targetSessionId
                if (getSessionStorage(sessionId) === "true") {
                    return false
                } else {
                    setSessionStorage(sessionId, true)
                    return checkDate(data.modal_date_range)
                }
            } else {
                return checkDate(data.modal_date_range)
            }
        } else {
            return false
        }
    }

    const closeModal = () => {
        setIsVisible(false)
    }

    const checkDate = (date) => {
        const today = new Date()
        if (date.is_set_period) {
            return new Date(date.is_visible_modal_start_date) <= today && today <= new Date(date.is_visible_modal_end_date);
        } else {
            return true
        }
    }

    useEffect(() => {
        callModalData()
        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

    useEffect(() => {
        modalData.sort((x, y) => Number(x.modal_visible_priority) - Number(y.modal_visible_priority));
        modalData.some(item => {
                if (checkModal(item)) {
                    setMobileImg(item.modal_mobile_image_url)
                    setPcImg(item.modal_desktop_image_url)
                    setButtonData(item.modal_button_setting)
                    if (item.modal_visible_page.point_of_exposure === "immediately") {
                        setIsVisible(true)
                    } else if (item.modal_visible_page.point_of_exposure === "in_seconds") {
                        if (timeoutId === undefined) {
                            timeoutId = setTimeout(() => setIsVisible(true), Number(item.modal_visible_page.in_seconds_value) * 1000)
                        }
                    }
                    return true
                } else {
                    return false
                }
            }
        )
    }, [location.pathname, modalData])

    const callModalData = async () => {
        await fetchAndReceiveJSON({url: `${apiUrl}/api/modal`, method: "GET", body: ''}).then(res => {
            let data = []
            if (Array.isArray(res)) {
                data = res
            } else {
                data = [res]
            }
            setModalData([...data])
        })
    }

    useEffect(() => {
        if (isVisible) {
            const windowScrollPosition = window.pageYOffset
            setScrollPosition(windowScrollPosition);
            document.body.style.position = 'fixed';
            document.body.style.left = '0';
            document.body.style.right = '0';
            document.body.style.top = `-${windowScrollPosition}px`;
        } else {
            document.body.style.removeProperty('position');
            document.body.style.removeProperty('left');
            document.body.style.removeProperty('right');
            document.body.style.removeProperty('top');
            window.scrollTo({left: 0, top: scrollPosition, behavior: "auto"})
        }
    }, [isVisible]);

    if (!isVisible) {
        return null
    }

    const CreateButton = () => {
        return (
            <div className="holiday-delivery-confirm-button-wrapper">
                {buttonData.map((button, i) => {
                    if (button.button_available) {
                        return (
                            <div key={i} className="holiday-delivery-confirm-button"
                                 style={{
                                     backgroundColor: button.button_background_color,
                                     color: button.button_font_color
                                 }}
                                 onClick={() => {
                                     if (button.action_on_click === "move_link") {
                                         window.location.href = button.link_url;
                                     } else if (button.action_on_click === "open_channel_talk") {
                                         callChatPlugIn()
                                     } else {
                                         closeModal();
                                     }
                                 }}>
                                <div>{button.button_font_text}</div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }


    return (
        <div className="holiday-delivery-modal-wrapper">
            <div className="dimmer" onClick={() => closeModal()}></div>
            <div className="holiday-delivery-modal">
                <picture>
                    <source srcSet={mobileImg} media="(max-width: 768px)"/>
                    <img className="holiday-delivery-modal-img" src={pcImg} onLoad={() => setImgLoaded(true)}/>
                </picture>
                {imgLoaded && <CreateButton/>}
            </div>
        </div>
    )
}

export default IsModal
