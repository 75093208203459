import React from "react";
import RestockingModal from "../components/RestockingModal/RestockingModal";
import IsModal from "../components/isModal/IsModal";

function ModalPage() {
    return (
        <>
            <RestockingModal/>
            <IsModal/>
        </>
    )

}

export default ModalPage
